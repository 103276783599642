<template>
    <div style="width: 100%;height:100%;max-height: 622px;">
        <div v-if="show" style="width: 100%;height:100%;">
            <img style="width: 100%;height:100%;" src="../images/logo.jpg" alt="">
        </div>
        <div style="width: 100%;height:100%;" v-if="operates">
            <div style="display:flex;width:100%;height:50%;">
                <div id="echarts_box" style="width: 50%;height:100%;max-height: 622px;"></div>
                <div id="echarts_box2" style="width: 50%;height:100%;max-height: 622px;"></div>
            </div>
            <div style="display:flex;width:100%;height:50%;">
                <div id="echarts_box3" style="width: 50%;height:100%;max-height: 622px;"></div>
                <div id="echarts_box4" style="width: 50%;height:100%;max-height: 622px;"></div>
            </div>
        </div>
        <div style="width: 100%;height:100%;" v-if="examine">
                <div id="echarts_box5" style="width: 100%;height:100%;max-height: 622px;"></div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import axios from 'axios';
import config from '../lib/config';

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
        all:false,
        show:false,
        examine:false,
        operates:false,
    }
  },
  mounted() {
    let _this = this;
    let interval =  setInterval(() => {
        if(location.examine != undefined){
                clearInterval(interval);
                let examine = location.examine;//审核
                let operates = location.operates;//运营
                if(examine == 1 && operates == 1){
                _this.getwaitItem();//待审核内容图
                _this.examine = true;
                    _this.getpeopleGrowth();//社区使用人数
                    _this.getdayActiveRate();//日活数
                    _this.getcontentRelease();//社区内容数量
                    _this.getinteraction();//单日互动量
                    _this.operates = true;
                    return
                }
                if(examine == 1){
                _this.getwaitItem();//待审核内容图
                _this.examine = true;
                    return
                }
                if(operates == 1){
                    _this.getpeopleGrowth();//社区使用人数
                    _this.getdayActiveRate();//日活数
                    _this.getcontentRelease();//社区内容数量
                    _this.getinteraction();//单日互动量
                    _this.operates = true;
                    return
                }
                _this.show = true;
         }
      },200);

		},
     methods: {
      getpeopleGrowth(){
        let _this = this;
        axios.get(config.peopleGrowth,{})
        .then(function (response) {
            if(response.data.code == 200){
                let data = response.data.data; 
                let option = {
                    title: {
                        text: '社区使用人数：'+data.num
                    },
                    tooltip: {
                        show:true,
                        trigger: 'axis',
                        formatter:function(params){
                        
                            return params[0].axisValue+"<br>"+"增长数"+params[0].data+"<br>"+"增长率"+data.rate[params[0].dataIndex]+'%';
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: data.time
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        name:'人数',
                        data: data.data,
                        type: 'line',
                        }
                    ]
                    };
                    let myChart = echarts.init(document.getElementById('echarts_box'));
                    myChart.setOption(option)
                 }else{
                    _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      getdayActiveRate(){
        let _this = this;
        axios.get(config.dayActiveRate,{})
        .then(function (response) {
            if(response.data.code == 200){
                let data = response.data.data; 
                let option = {
                    title: {
                        text: '日活跃度'
                    },
                    tooltip: {
                        show:true,
                        trigger: 'axis',
                        formatter:function(params){
                        
                            return params[0].axisValue+"<br>"+"日新增活跃数"+params[0].data+"<br>"+"日活增长率"+data.rate[params[0].dataIndex]+'%';
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: data.time
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        name:'人数',
                        data: data.data,
                        type: 'line',
                        }
                    ]
                    };
                    let myChart = echarts.init(document.getElementById('echarts_box2'));
                    myChart.setOption(option)
                 }else{
                    _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      getcontentRelease(){
        let _this = this;
        axios.get(config.contentRelease,{})
        .then(function (response) {
            if(response.data.code == 200){
                let data = response.data.data; 
                let option = {
                    title: {
                        text: '社区内容数量'
                    },
                    tooltip: {
                        show:true,
                        trigger: 'axis',
                        formatter:function(params){
                            return params[0].axisValue+"<br>"+"总数"+data.all_num[params[0].dataIndex]+"<br>总增长率"+data.all_num_rate[params[0].dataIndex]+
                                   '%<br>内容数'+data.article_num[params[0].dataIndex]+"<br>内容增长率"+data.article_num_rate[params[0].dataIndex]+
                                   '%<br>评论数'+data.comment_num[params[0].dataIndex]+"<br>评论增长率"+data.comment_num_rate[params[0].dataIndex]+'%';
                        },
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: data.time
                    },
                    series: [
                        {
                        name: '单日文字发布量',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: data.article_num
                        },
                        {
                        name: '单日评论发布量',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: data.comment_num
                        }
                    ]
                    };
                    let myChart = echarts.init(document.getElementById('echarts_box3'));
                    myChart.setOption(option)
                 }else{
                    _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      getinteraction(){
        let _this = this;
        axios.get(config.interaction,{})
        .then(function (response) {
            if(response.data.code == 200){
                let data = response.data.data; 
                let option = {
                    title: {
                        text: '单日互动量'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                        }
                    },
                    legend: {
                        data: ['单日互动量', '单日增长率']
                    },
                    xAxis: [
                        {
                        type: 'category',
                        data: data.time,
                        axisPointer: {
                            type: 'shadow'
                        }
                        }
                    ],
                    yAxis: [
                        {
                        type: 'value',
                        name: '互动量',
                        },
                        {
                        type: 'value',
                        name: '增长率',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                        }
                    ],
                    series: [
                        {
                        name: '互动量',
                        type: 'bar',
                        data: data.all_num
                        },
                        {
                        name: '增长率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: data.all_num_rate
                        }
                    ]
                    };
                    let myChart = echarts.init(document.getElementById('echarts_box4'));
                    myChart.setOption(option)
                 }else{
                    _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      getwaitItem(){
        let _this = this;
        axios.get(config.waitItem,{})
        .then(function (response) {
            if(response.data.code == 200){
                let data = response.data.data; 
                let num = data.article_examine + data.comment_examine + data.description_examine + data.head_examine + data.nickname_examine + data.report ;
                let option = {
                        title: {
                            text: '待审核内容',
                            subtext: '总量：'+num,
                            left: 'center'
                        },
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left'
                        },
                        series: [
                            {
                            name: '待审核数量',
                            type: 'pie',
                            radius: '50%',
                            label: {
                                position: 'inside',//outside
                                fontSize: 14,
                                formatter: function(params){//params[0].dataIndex
                                    return params.data.name+':'+params.data.value;
                                },
                            },
                            data: [
                                { value: data.article_examine, name: '内容' },
                                { value: data.comment_examine, name: '评论' },
                                { value: data.description_examine, name: '简介' },
                                { value: data.head_examine, name: '头像' },
                                { value: data.nickname_examine, name: '昵称' },
                                { value: data.report, name: '反馈' }
                            ],
                            emphasis: {
                                itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                            }
                        ]
                        };
                    let myChart = echarts.init(document.getElementById('echarts_box5'));
                    myChart.setOption(option)
                 }else{
                    _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
  },
}
</script>
